<template>
	<div class="container" v-if="channelList.length > 0">
		<h2 class="iq-title justify-content-center heading title--header">
			Watch us on
		</h2>
		<div class="row justify-content-center">
			<div
				class="col-6 col-md-3 channel"
				v-for="channel in channelList"
				:key="channel.id"
			>
				<channel-card
					:title="channel.name"
					:image="channel.image"
					:link="channel.url"
				></channel-card>
			</div>
		</div>
	</div>
</template>

<script>
import ChannelCard from "./ChannelCard.vue";
import { getTelevisionProviderInformationWithImages } from "../../../../../services/channels";

export default {
	components: {
		ChannelCard,
	},
	data() {
		return {
			channelList: [],
		};
	},
	mounted() {
		this.channelList = getTelevisionProviderInformationWithImages().map(
			({ id, name, url, image }) => ({
				id,
				name,
				url,
				image: require(`../../../../../assets/images/channels/${image}`),
			})
		);
	},
};
</script>

<style scoped>
.channel {
	min-width: 8vh;
	padding: 4px;
}
</style>
